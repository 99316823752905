p{
    font-weight: 400px;
}
.first-table{
    width: 530px;
    height: 50px;
    margin: auto;
    
}
/* .first-table ::after {
    position: absolute;
    display: block;
    content: "";
    width: 100px;
    left: 50%;
    height: 2px;
    margin-top: 10px;
    transform: translateX(-50%);
    background-color: black;
} */

.table-title{
    margin-top: 100px;
    color: red;
    font-family: 'Shadows Into Light', cursive;
    font-weight: 900;
}

 

.table-title2{
    color: black;
    font-family: 'Shadows Into Light', cursive;
    font-weight: 900;
}
.table-title2::after {
    position: absolute;
    display: block;
    content: "";
    width: 100px;
    left: 50%;
    height: 2px;
    margin-top: 10px;
    transform: translateX(-50%);
    background-color: black;
  }
.table-title3{
    margin-top: 100px;
    color: black;
    font-family: 'Shadows Into Light', cursive;
    font-weight: bolder;
}
.table-title3::after {
    position: absolute;
    display: block;
    content: "";
    width: 150px;
    left: 50%;
    height: 3px;
    margin-top: 10px;
    transform: translateX(-50%);
    background-color: black;
  }
.minting{
    margin-bottom: 2px solid green;
}
.table-head{
    background-color: #8C30F5;
    
}
.middle-text{
    width: 500px;
}
.sold{
    color: green;
}
.fisherman{
    max-width: 1080px;
    margin: auto;

}
.action{
    width: 360px;
}
.whitepaper-text{
    justify-content: center;
    max-width: 700px;
    margin: auto;
}
.bears-table{
    max-width: 1046px;
    margin: auto;
}
.middle-text{
    margin: auto;
}
.middle-title{
    color: #0A901B;
}
.bottom-text{
    margin: auto;
    max-width: 1100px;
}
.title{
    color: #0A901B;
}
.bottom-text h1{
    color: #0A901B;
    margin: 50px;
}
.bottom-text p{
    margin: 10px;
    font-weight: 400px;
    font-size: 14px;
}