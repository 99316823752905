.form-bg {
  background-image: url(../../assets/image/background1.png);
  background-repeat: no-repeat;
  background-position: center center;
  padding: 5.7rem 0;
  background-attachment: fixed;
  background-size: cover;
  height: calc(100vh - 50px);
  overflow-y: auto;
}
.form-bg::-webkit-scrollbar {
  display: none;
}

.bg-tran {
  padding: 20px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  max-width: 600px;
  margin: 0 auto;
}
.stGen {
  background-color: #fff;
  padding: 5px;
  display: inline-block;
  border-radius: 10px;
}
.my-btn {
  border: none;
  padding: 18px 30px;
  border-radius: 10px 0 0 10px;
  background-color: #8c30f5;
}
.my-input {
  border: none;
  padding: 6px 0;
  padding-left: 10px;
}
.my-input:focus {
  outline: none;
}

.connect-wallet {
  background-color: #8c30f5;
  padding: 8px 20px;
  color: #fff;
  border-radius: 10px;
  border: none;
}
.my-color {
  background-color: #8c30f5;
  border-radius: 50%;
  border: none;
  color: #fff;
  font-weight: 900;
  padding: 0 7px 2px 6px;
}
.increment {
  padding: 0 9px 2px 9px;
}
.midel-input {
  width: 2.5rem;
  height: 1.3rem;
  margin: 0 3px;
  border-radius: 10px;
  border: 1px solid #8c30f5;
  text-align: center;
}

.card__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1em 0;
  grid-gap: 1em;
  overflow-x: auto;
  overflow-y: hidden;
}

.nft_image {
  width: 200px;
  height: 200px;
  border: 5px solid transparent;
  transition: 300ms all linear;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.nft_image img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.nft_image.active {
  border: 5px solid #fff;
  /* transform: scale(1.1); */
}

.flex-even {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
}

h6 {
  width: 100%;
  padding-bottom: 10px;
  border: 1px solid transparent;
  transition: 250ms all ease-in-out;
  cursor: pointer;
}

h6.active {
  border-bottom: 1px solid #fff;
}

.block_header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.block_header a {
  color: #000;
  text-decoration: none;
  font-size: 1.25em;
  font-weight: 600;
}
